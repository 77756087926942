import React, { useState } from 'react'
import { Divide as Hamburger } from 'hamburger-react'
import { PrismicLink } from '@prismicio/react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import Dropdown from './dropdown'

const headerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicConfig {
      ...PrismicConfigFragment
    }
  }
`

const Header = () => {
  const [isOpen, setOpen] = useState(false)

  let navStyles =
    'group px-4 py-3 w-full lg:w-auto lg:p-0 bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-gray-500'
  let subNavStyles =
    'text-xl block px-6 py-3 lg:p-4 bg-blue-50 lg:hover:bg-blue-50 lg:bg-white flex justify-between text-base font-medium lg:opacity-80 transition duration-150 ease-in-out lg:text-gray-800 lg:hover:text-blue-700 lg:focus:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-gray-500'

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {
        const headerData = data.prismicNavigation.data
        const config = data.prismicConfig.data

        return (
          <header role='banner' className='z-20 bg-white text-blue-700'>
            <nav className='relative mx-auto flex max-w-screen-2xl items-center p-6'>
              <Link to='/' className='relative z-20 mr-6 flex flex-row items-center'>
                <GatsbyImage image={config.company_logo.gatsbyImageData} alt='' role='presentation' />
                <div className='ml-4 text-xl uppercase text-blue-700 lg:text-2xl'>{config.company_display_name}</div>
              </Link>
              <span className='z-20 ml-auto md:hidden'>
                <Hamburger color='#0024EF' label='Show menu' rounded toggled={isOpen} toggle={setOpen} />
              </span>
              <div
                className={`${
                  !isOpen ? 'hidden' : 'fixed inset-0 z-10 flex h-screen flex-col justify-start bg-black bg-opacity-25 p-2'
                } space-x-10 md:relative md:flex md:h-auto md:w-full md:flex-row md:justify-end md:bg-transparent`}>
                <div className='lg:max-h-none max-h-screen overflow-y-scroll rounded bg-white shadow-lg lg:overflow-visible lg:rounded-none lg:bg-transparent lg:shadow-none'>
                  <ul className='relative flex-row items-center pt-32 pb-10 text-xl md:flex md:py-0 md:text-base lg:space-x-10'>
                    {headerData.nav.map((nav, index) => {
                      return (
                        <React.Fragment key={`nav-${index}`}>
                          {nav.items.length > 1 ? (
                            <Dropdown items={nav.items} navStyles={navStyles} subNavStyles={subNavStyles}>
                              {nav.primary.label}
                            </Dropdown>
                          ) : (
                            <li key={`nav-${index}`}>
                              <PrismicLink className={navStyles} field={nav.primary.link}>
                                {nav.primary.label}
                              </PrismicLink>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Header
