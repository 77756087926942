import React from 'react'
import PropTypes from 'prop-types'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

const Text = ({ slice }) => {
  return (
    <section className='component-text mx-auto w-full max-w-screen-xl px-6 py-10'>
      <div className='prose prose-lg prose-blue mx-auto'>
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      {slice.primary.button_link.url != null && (
        <div className='mx-auto mt-4 block max-w-3xl pt-4 text-center'>
          <PrismicLink className='button' field={slice.primary.button_link}>
            {slice.primary.button_text}
          </PrismicLink>
        </div>
      )}
    </section>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
