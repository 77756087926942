import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const Counter = ({ slice }) => {
  return (
    <section className='component-counter mx-auto flex w-full max-w-screen-xl flex-col gap-4 py-10 px-6 md:py-16'>
      <div className='prose prose-lg mx-auto text-center'>
        <PrismicRichText field={slice.primary.title.richText} />
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        {({ isVisible }) => (
          <div className='flex flex-col justify-evenly md:flex-row'>
            {slice.items.map((counter, index) => {
              return (
                <div key={`counter-${index}`} className='flex flex-col p-8 text-center leading-none md:p-16 md:pb-0'>
                  <CountUp
                    className='font-serif text-4xl text-blue-700 md:text-5xl'
                    delay={slice.primary.delay}
                    end={counter.final_number}
                    suffix={counter.suffix || ''}
                    prefix={counter.prefix || ''}
                    separator=','
                    decimal='.'
                    duration={slice.primary.duration}
                  />
                  <div>
                    <PrismicRichText field={counter.title.richText} />
                    <PrismicRichText field={counter.text.richText} />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </VisibilitySensor>
    </section>
  )
}

export default Counter
