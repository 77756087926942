import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import Social from './social'
// import Mailchimp from './mailchimp'

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicConfig {
      ...PrismicConfigFragment
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={(data) => {
        const footerData = data.prismicNavigation.data
        const config = data.prismicConfig.data

        return (
          <>
            <div className='mt-auto border-t bg-gray-50'>
              <div className='prose prose-lg mx-auto max-w-2xl py-16 px-4 text-center sm:px-6 lg:px-8'>
                <h2>A team you can trust</h2>
                <ul className={`mt-6 grid grid-cols-2 gap-8 md:grid-cols-${config.partner.length}`}>
                  {config.partner.map((partner, index) => {
                    return (
                      <li key={`partner-${index}`} className='col-span-1 flex justify-center opacity-70 grayscale md:col-span-2 lg:col-span-1'>
                        <PrismicLink field={partner.link}>
                          <GatsbyImage image={partner.logo.gatsbyImageData} alt={partner.logo.alt || ''} />
                        </PrismicLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <footer role='contentinfo' className='bg-gradient-to-r from-blue-800 to-blue-900'>
              <div className='mx-auto grid max-w-screen-2xl grid-cols-1 gap-10 px-6 py-10 text-white md:grid-cols-3'>
                <div>
                  <div className='mb-4 font-serif uppercase'>{config.company_display_name}</div>
                  <PrismicRichText field={config.company_address.richText} />
                  {config.company_phone}
                </div>
                {footerData.body.map((footer, index) => {
                  return (
                    <div key={`footer-${index}`}>
                      <div className='mb-4 font-serif uppercase text-white'>{footer.primary.title}</div>
                      <ul>
                        {footer.items.map((footerItem, index) => {
                          return (
                            <li className='my-2' key={`footerItem-${index}`}>
                              <PrismicLink className='py-2 text-white hover:underline' field={footerItem.link}>
                                {footerItem.link_label}
                              </PrismicLink>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })}
                <div>
                  <div className='mb-4 font-serif uppercase text-white'>Social</div>
                  <Social />
                </div>
              </div>
              <div className='bg-gray-800 py-4 text-center text-sm text-white'>
                <div className='mx-auto flex max-w-screen-2xl flex-col justify-between gap-2 px-4 md:flex-row'>
                  <div>Edge Security Solutions © 2021 - Company Number: 12258634</div>
                  <div>
                    Made by
                    <a href='https://www.thejuniperstudio.com/' target='_blank' rel='noopener noreferrer'>
                      <span className='mx-1 font-serif font-semibold text-indigo-300'> The Juniper Studio</span>
                    </a>
                    &copy; {new Date().getFullYear()}. All rights reserved.
                  </div>
                </div>
              </div>
            </footer>
          </>
        )
      }}
    />
  )
}

export default Footer
