import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

const Accordion = ({ slice }) => {
  return (
    <section className='component-accordion mx-auto w-full max-w-3xl py-10 px-6 md:py-16'>
      <div className='prose prose-lg prose-blue max-w-none'>
        <PrismicRichText field={slice.primary.title.richText} />
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <div className='mt-6 space-y-6 divide-y'>
        {slice.items.map((accordion, index) => {
          return (
            <details className='pt-6 transition-all' key={`accordion-${index}`}>
              <summary className='m-0 flex w-full cursor-pointer justify-between py-2 font-serif font-semibold outline-none hover:text-blue-700 active:text-blue-900'>
                <PrismicRichText field={accordion.question.richText} />
                <div className='indicator flex items-center'>
                  <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='currentColor'>
                    <path d='M0 0h24v24H0V0z' fill='none' />
                    <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
                  </svg>
                </div>
              </summary>
              <div className='prose my-3 max-w-none pr-12 transition-all'>
                <PrismicRichText field={accordion.answer.richText} />
              </div>
            </details>
          )
        })}
      </div>
    </section>
  )
}

Accordion.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Accordion
