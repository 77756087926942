import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import PropTypes from 'prop-types'

// Components
import Card from './card'

const CardDeck = ({ slice }) => {
  var grid = 'md:grid-cols-3'
  return (
    <section className='component-card_deck mx-auto w-full max-w-screen-xl py-4 px-6 md:py-10'>
      <div className='prose prose-lg mx-auto py-4 px-6 text-center md:py-10'>
        <PrismicRichText field={slice.primary.deck_title.richText} />
      </div>
      <ul className={`grid grid-cols-1 gap-4 ${grid}`}>
        {slice.items.map((card, index) => {
          return <Card fields={card} key={`card-${index}`} />
        })}
      </ul>
    </section>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired
}

export default CardDeck
