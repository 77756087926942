import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver'

import PageTemplate from '../templates/page'
import BlogTemplate from '../templates/blogpost'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blogpost: BlogTemplate,
      page: PageTemplate
    })
  }
]
