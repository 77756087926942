import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

// Components
import Header from './header'
import Footer from './footer'

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, lang }) => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={() => (
        <div className='flex min-h-screen flex-col text-gray-800 antialiased'>
          <Header lang={lang} />
          <main role='main' id='main'>
            {children}
          </main>
          <Footer lang={lang} />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='mx-1 px-4 py-2 inline-block rounded-lg border transform transition duration-150 ease-in-out border-white text-white hover:shadow-sm hover:-translate-y-1 hover:shadow-md'
            declineButtonClasses='mx-1 px-4 py-2 inline-block'
            containerClasses='fixed inset-x-0 text-white bg-gray-900 p-3 text-sm text-center'
            contentClasses='inline-block mb-2'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            {process.env.GATSBY_COMPANY_NAME} uses cookies.{' '}
            <Link to='/privacy' className='underline'>
              What do we use cookies for?
            </Link>
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string
}

export default Layout
