import React from 'react'
import { PrismicRichText } from '@prismicio/react'

const Contact = ({ slice }) => {
  return (
    <section className='component-contact mx-auto w-full max-w-4xl py-10 px-6'>
      {slice.primary.text.richText && (
        <div className='prose prose-lg prose-blue col-span-2 max-w-screen-2xl lg:pr-10'>
          <PrismicRichText field={slice.primary.text.richText} />
        </div>
      )}
      <form name='contact' method='POST' action='/success' data-netlify='true' data-netlify-honeypot='surname'>
        <input type='hidden' name='surname' />
        <input type='hidden' name='form-name' value='contact' />
        <div className='mb-4'>
          <label className='mt-4 mb-2 block font-serif text-lg font-semibold' htmlFor='name'>
            Name
          </label>
          <input className='w-full border p-3 leading-tight text-gray-700 shadow' type='text' name='name' id='name' required />
        </div>
        <div className='mb-4'>
          <label className='mt-4 mb-2 block font-serif text-lg font-semibold' htmlFor='email'>
            Email
          </label>
          <input className='w-full border p-3 leading-tight text-gray-700 shadow' type='email' name='email' id='email' required />
        </div>
        <div className='mb-4'>
          <label className='mt-4 mb-2 block font-serif text-lg font-semibold' htmlFor='phone'>
            Phone number
          </label>
          <input className='w-full border p-3 leading-tight text-gray-700 placeholder-gray-500 shadow' type='tel' name='phone' id='phone' placeholder='Optional' />
        </div>
        <div className='mb-4'>
          <label className='mt-4 mb-2 block font-serif text-lg font-semibold' htmlFor='message'>
            How can we help?
          </label>
          <textarea rows='4' className='w-full border p-3 leading-tight text-gray-700 shadow' name='message' id='message' required></textarea>
        </div>
        <div className='mb-4'>
          <button type='submit' className='button w-full sm:w-auto'>
            Send your enquiry
          </button>
        </div>
      </form>
    </section>
  )
}

export default Contact
