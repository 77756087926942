import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Quote = ({ slice }) => {
  return (
    <section className='component-map mx-auto w-full max-w-screen-xl border-y p-10 md:my-10 md:px-6'>
      <blockquote className='relative'>
        <div className='md:flex'>
          <svg className='absolute left-0 top-0 mr-3 h-12 w-12 -translate-x-6 -translate-y-6 text-blue-700 opacity-30 md:relative md:h-16 md:w-16' fill='currentColor' viewBox='0 0 32 32'>
            <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'></path>
          </svg>
          {slice.primary.quote && (
            <div className='relative flex-1 text-2xl'>
              <PrismicRichText field={slice.primary.quote.richText} />
            </div>
          )}
        </div>
        <footer className='mt-4 flex items-center md:justify-end'>
          <div className='inline-flex flex-shrink-0 rounded-full border-2 border-white'>
            {slice.primary.image.gatsbyImageData && (
              <div className='transform overflow-hidden transition duration-500'>
                <GatsbyImage className='h-24 w-24 rounded-full border-2 bg-white' image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
              </div>
            )}
          </div>
          <div className='ml-4'>
            <p className='font-serif text-base font-semibold'>{slice.primary.author}</p>
            <p className='text-sm font-medium opacity-50'>
              {slice.primary.role && <span>{slice.primary.role}</span>}
              {slice.primary.company && slice.primary.role && ','}
              {slice.primary.company && <span> {slice.primary.company}</span>}
            </p>
          </div>
        </footer>
      </blockquote>
    </section>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Quote
