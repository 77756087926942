import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

const Headshots = ({ slice }) => {
  return (
    <section className='component-card_deck mx-auto w-full max-w-screen-xl space-y-6 py-10 px-6 md:py-20'>
      <div>
        <PrismicRichText field={slice.primary.title.richText} />
      </div>
      <ul className='gap-5 space-y-12 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3 lg:gap-10'>
        {slice.items.map((person, index) => {
          return (
            <li key={person.name}>
              <div className='space-y-6'>
                <div className='aspect-w-3 aspect-h-2'>
                  <GatsbyImage image={person.image.gatsbyImageData} alt={person.image.alt || ''} className='rounded object-cover shadow-lg' />
                </div>
                <div className='space-y-1 text-lg font-medium leading-6'>
                  <div className='flex items-center space-x-2'>
                    <h3>{person.name}</h3>
                    {person.linkedin.url && (
                      <a href={person.linkedin.url} className='text-gray-400 hover:text-gray-500'>
                        <span className='sr-only'>LinkedIn</span>
                        <svg className='h-5 w-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fillRule='evenodd'
                            d='M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                  <p className='text-blue-700'>{person.role}</p>
                </div>
                <div className='prose prose-lg prose-blue'>
                  <PrismicRichText field={person.bio.richText} className='text-gray-500' />
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

Headshots.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Headshots
