import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

const BannerWithCaption = ({ slice }) => {
  return (
    <section className='my-20 bg-gradient-to-r from-blue-700 to-blue-900 pb-16 lg:relative lg:z-10 lg:pb-0'>
      <div className='lg:mx-auto lg:grid lg:max-w-screen-2xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
        <div className='relative lg:-my-8'>
          <div className='mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:h-full lg:p-0'>
            <div className='aspect-w-10 aspect-h-6 sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none overflow-hidden rounded-xl shadow-xl lg:h-full'>
              <GatsbyImage image={slice.primary.image.gatsbyImageData} className='h-full w-full object-cover' alt={slice.primary.image.alt || ''} />
            </div>
          </div>
        </div>
        <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
          <div className='prose prose-lg prose-invert  mx-auto max-w-md px-4 text-white sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
            <PrismicRichText field={slice.primary.title.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
            {slice.primary.button_link.url && (
              <div className='mt-6'>
                <div className='inline-flex'>
                  <PrismicLink className='button border-white bg-transparent text-xl text-white hover:border-white hover:bg-blue-600' field={slice.primary.button_link}>
                    {slice.primary.button_text}
                  </PrismicLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired
}

export default BannerWithCaption
