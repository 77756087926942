import React from 'react'
import { graphql } from 'gatsby'
import { format, parseISO } from 'date-fns'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText } from '@prismicio/react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query Blogpost($id: String) {
    prismicBlogpost(id: { eq: $id }) {
      ...PrismicBlogpostFragment
    }
  }
`

const BlogpostTemplate = (props) => {
  const blogpostData = props.data.prismicBlogpost.data

  if (props.data.prismicBlogpost.first_publication_date) {
    var parseDate = parseISO(props.data.prismicBlogpost.first_publication_date)
    var formattedDate = format(new Date(parseDate), 'EEEE, do MMMM yyyy')
  }

  const wordsPerMinute = 200
  let wordCount = blogpostData.blogpost_text.text.split(' ').length
  let timeToRead = Math.ceil(wordCount / wordsPerMinute)

  let images = false
  if (blogpostData.blogpost_image.gatsbyImageData) {
    images = withArtDirection(getImage(blogpostData.blogpost_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(blogpostData.blogpost_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout>
      <Seo
        title={props.data.prismicBlogpost.meta_title ? props.data.prismicBlogpost.meta_title : blogpostData.title}
        keywords={props.data.prismicBlogpost.meta_keywords}
        description={props.data.prismicBlogpost.meta_description}
        lang={props.data.prismicBlogpost.lang}
      />
      <div className='hero relative'>
        <div className='absolute inset-x-0 bottom-0 h-1/2 bg-gray-100' />
        <div className='mx-auto max-w-screen-2xl sm:px-6 lg:px-8'>
          <div className='relative min-h-screen-1/3 shadow-xl sm:overflow-hidden sm:rounded-2xl'>
            <div className='absolute inset-0'>
              <GatsbyImage className='h-full w-full object-cover' image={images} alt={blogpostData.blogpost_image.alt || ''} />
              <div className='absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-500 to-yellow-300 mix-blend-multiply' />
            </div>
            <div className='relative flex min-h-screen-1/3 flex-col items-center justify-center px-4 py-16 text-white sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
              <PrismicRichText field={blogpostData.blogpost_title.richText} />
              <div className='text-center md:mt-10'>
                {blogpostData.author[0].image.gatsbyImageData && (
                  <GatsbyImage
                    className='my-2 mx-auto h-16 w-16 rounded-full border border-yellow-300 bg-white p-2'
                    image={blogpostData.author[0].image.gatsbyImageData}
                    alt={blogpostData.author[0].image.alt || ''}
                  />
                )}
                <div className='ml-3 text-center'>
                  <p className='font-semibold text-white'>
                    {blogpostData.author[0].name}
                    {blogpostData.author[0].position && (
                      <>
                        <span aria-hidden='true'> &middot; </span>
                        <span> {blogpostData.author[0].position}</span>
                      </>
                    )}
                  </p>
                  {parseDate && (
                    <div className='font-semibold text-white'>
                      <time dateTime={parseDate}>{formattedDate}</time>
                      <span aria-hidden='true'> &middot; </span>
                      <span>{timeToRead} min read</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-100'>
        <div className='prose prose-lg prose-blue mx-auto py-10 text-gray-500'>
          <PrismicRichText field={blogpostData.blogpost_text.richText} />
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(BlogpostTemplate)
