import React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = ({ data }) => {
  const page = data.prismicPage
  const pageData = page.data

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.thumbnails) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout lang={pageData.lang}>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.title} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='hero relative'>
        <div className='absolute inset-x-0 bottom-0 h-1/2 bg-gray-100' />
        <div className='mx-auto max-w-screen-2xl sm:px-6  lg:px-8'>
          <div className='relative min-h-screen-1/3 shadow-xl sm:overflow-hidden sm:rounded-2xl'>
            <div className='absolute inset-0'>
              <GatsbyImage className='h-full w-full object-cover' image={images} alt={pageData.page_image.alt || ''} />
              <div className='absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-500 to-yellow-300 mix-blend-multiply' />
            </div>
            <div className='relative flex min-h-screen-1/3 flex-col items-center justify-center px-4 py-16 text-white sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
              <PrismicRichText field={pageData.page_title.richText} />
              {pageData.page_text.text && (
                <div className='mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl'>
                  <PrismicRichText field={pageData.page_text.richText} />
                </div>
              )}
              {pageData.page_button_text && pageData.page_button_link && (
                <div className='mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center'>
                  <PrismicLink
                    className='flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-semibold text-blue-700 shadow-sm hover:bg-blue-50 sm:px-8'
                    field={pageData.page_button_link}>
                    {pageData.page_button_text}
                  </PrismicLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-100'>
        <SliceList slices={pageData.body} />
      </div>
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate)
