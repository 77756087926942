import React from 'react'
import PropTypes from 'prop-types'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { PrismicRichText } from '@prismicio/react'

const Video = ({ slice }) => {
  if (slice.primary.video === null) return null
  var embed = slice.primary.video.embed_url
  if (embed !== null && embed.includes('watch')) {
    embed = embed.split('v=')[1]
    var ampersandPosition = embed.indexOf('&')
    if (ampersandPosition !== -1) {
      embed = embed.substring(0, ampersandPosition)
    }
  }

  return (
    <section className='component-video bg-gray-900'>
      <div className='mx-auto w-full max-w-screen-xl py-10 md:px-6 md:py-16'>
        <div className='mb-4 px-6 text-center text-white md:px-0'>
          <PrismicRichText field={slice.primary.title.richText} />
        </div>
        <LiteYouTubeEmbed id={embed} title={slice.primary.video.title} />
      </div>
    </section>
  )
}

Video.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Video
