import React from 'react'
import PropTypes from 'prop-types'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Arrow from './svg/arrow'

const Hero = ({ slice }) => {
  return (
    <section className='component-hero'>
      <div className='grid grid-cols-1 grid-rows-1 overflow-hidden md:grid-cols-2'>
        {slice.primary.image.gatsbyImageData && (
          <div className='relative col-span-full h-full md:row-span-full'>
            <GatsbyImage className='relative inset-0 h-full w-full object-cover' image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
          </div>
        )}
        <div className='hero col-span-full -mt-12 flex w-full flex-col justify-center px-6 pb-6 md:row-span-full md:mx-auto md:mt-0 md:max-w-screen-xl md:py-32 lg:py-52'>
          <div className='group relative flex flex-col items-start justify-center gap-4 bg-gray-800 py-8 px-12 shadow md:w-2/3 md:bg-opacity-90'>
            <div className='prose prose-lg prose-invert min-w-full'>
              <PrismicRichText field={slice.primary.title.richText} />
              <PrismicRichText field={slice.primary.text.richText} />
            </div>
            <PrismicLink className='flex items-center py-2 font-serif text-white' field={slice.primary.button_link}>
              {slice.primary.button_text}
              <Arrow className='inline-block h-6 w-6 transform text-gray-300 transition duration-200 group-hover:translate-x-full group-hover:text-blue-700' />
              <span className='absolute inset-0' aria-hidden='true'></span>
            </PrismicLink>
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Hero
