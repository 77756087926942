import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import EmblaCarousel from './embla/emblaCarousel'

const Image = ({ slice }) => {
  return (
    <section className='component-image_gallery mx-auto w-full max-w-screen-xl py-4 text-center md:py-10 md:px-6'>
      {slice.primary.gallery_name.text && (
        <div className='prose prose-xl mb-10 max-w-none text-center'>
          <PrismicRichText field={slice.primary.gallery_name.richText} />
        </div>
      )}
      {slice.items.length > 1 ? (
        <EmblaCarousel autoplay={true} arrows={true} delayLength={4000} dots={true} loop={true}>
          {slice.items.map((item, index) => {
            return (
              <div className={`relative min-w-full ${'item' + (index === 0 ? ' active' : '')}`} key={`item-${index}`} aria-selected={index === 0 ? 'true' : 'false'}>
                {item.image.gatsbyImageData && <GatsbyImage image={item.image.gatsbyImageData} alt={item.image.alt || ''} />}
                {item.text.richText && (
                  <div className='caption bottom-0 z-10 w-full bg-gray-800 py-3 px-6 text-center text-white md:absolute md:bg-opacity-80'>
                    <PrismicRichText field={item.text.richText} />
                  </div>
                )}
              </div>
            )
          })}
        </EmblaCarousel>
      ) : (
        <GatsbyImage className='mx-auto inline-block rounded-xl bg-white text-center shadow-xl' image={slice.items[0].image.gatsbyImageData} alt={slice.items[0].image.alt || ''} />
      )}
    </section>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Image
