import React, { useState, useRef } from 'react'
import useOnClickOutside from './hooks/useOnClickOutside'
import { PrismicLink } from '@prismicio/react'
import Arrow from './svg/arrow'

const Dropdown = (props) => {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)
  const dropdownToggle = () => setOpen(!isOpen)

  useOnClickOutside(ref, () => setOpen(false))

  return (
    <li ref={ref} className='relative'>
      <button aria-expanded={isOpen} aria-haspopup='true' className={props.navStyles} onClick={dropdownToggle} onKeyDown={dropdownToggle}>
        {props.children}
        <Arrow className='ml-1 inline-block h-5 w-5 rotate-90 text-current transition' />
      </button>
      <ul className={`${!isOpen ? 'hidden' : 'flex min-w-max flex-col md:absolute md:left-0 md:mt-2 md:rounded-lg md:p-2 md:shadow-lg lg:bg-white'}`}>
        {props.items.map((subNav, index) => {
          return (
            <li key={`subNav-${index}`}>
              <PrismicLink className={props.subNavStyles} field={subNav.subnav_link} tabIndex='-1'>
                {subNav.subnav_link_label}
                <Arrow className='inline-block h-6 w-6 text-gray-300 md:hidden' />
              </PrismicLink>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default Dropdown
