import React from 'react'
import { SliceZone } from '@prismicio/react'

// Slices
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Headshots from './headshots'
import Hero from './hero'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Text from './text'
import Video from './video'

const components = {
  accordion: Accordion,
  banner_with_caption: BannerWithCaption,
  card_deck: CardDeck,
  contact: Contact,
  counter: Counter,
  headshots: Headshots,
  hero: Hero,
  image_gallery: Image,
  map: Map,
  quote: Quote,
  text: Text,
  video: Video
}

const SliceList = ({ slices }) => {
  return <SliceZone slices={slices} components={components} defaultComponent={() => null} />
}

export default SliceList
