import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Arrow from './svg/arrow'

const Card = ({ fields }) => {
  return (
    <li
      className={`component-card ${
        fields.button_link.url !== null && 'group'
      } relative flex flex-grow transform flex-col overflow-hidden rounded-lg bg-white shadow-lg transition duration-150 ease-in-out`}>
      {fields.image.gatsbyImageData && (
        <div className='overflow-hidden'>
          <GatsbyImage className='transform transition duration-500 group-hover:scale-110' image={fields.image.gatsbyImageData} alt={fields.image.alt || ''} />
        </div>
      )}
      <div className='flex flex-grow flex-col gap-4 p-4'>
        <PrismicRichText field={fields.title.richText} />
        <div className='prose prose-lg prose-blue max-w-none'>
          <PrismicRichText field={fields.text.richText} />
        </div>
        <PrismicLink className={`${!fields.button_text && 'link'}  mt-auto flex items-center pt-2 font-serif`} field={fields.button_link}>
          {fields.button_text} <span className='sr-only'>about {fields.title.text}</span>
          {fields.button_text && <Arrow className='inline-block h-6 w-6 transform text-gray-300 transition duration-200 group-hover:translate-x-full group-hover:text-blue-700' />}
          <span className='absolute inset-0' aria-hidden='true'></span>
        </PrismicLink>
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired
}

export default Card
